import { CloseOutlined } from "@material-ui/icons";
import cn from "classnames";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  label?: string;
  leadingIcon?: any;
  trailingIcon?: any;
  error?: string;
  type?: "text" | "number";
  onTrailingClick?: any;
  isClearable?: boolean;
  onChange?: any;
}

const Input: React.FC<InputProps> = ({
  className,
  label,
  trailingIcon,
  leadingIcon,
  error,
  type = "text",
  onTrailingClick,
  isClearable = false,
  ...props
}) => {
  let LeadingIcon;
  if (leadingIcon) {
    LeadingIcon = leadingIcon;
  }
  let TrailingIcon;
  if (trailingIcon) {
    TrailingIcon = trailingIcon;
  }

  return (
    <div className={cn("relative mb-2", className)}>
      {label && (
        <div className="flex flex-row items-center">
          {label ? (
            <label htmlFor={props.id} className="mr-2 text-jll-text-base-subdued">
              {label}
            </label>
          ) : (
            <span></span>
          )}
        </div>
      )}
      <div className={cn("relative rounded-md shadow-sm", { "mt-2": !!label })}>
        {leadingIcon && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <LeadingIcon className="h-5 w-5 text-jll-icon-base-subdued" />
          </div>
        )}
        <input
          className={cn(
            "block w-full rounded border focus:border-jll-light-stroke-default focus:ring-0 py-1.5 px-2 text-jll-text-base-subdued",
            {
              "pl-10": !!leadingIcon,
              "pr-10": !!trailingIcon,
              //   "border-jll-light-stroke-default": !!error,
              "border-jll-light-stroke-default": !error,
            }
          )}
          type={type}
          {...props}
        />
        {trailingIcon && (
          <div
            className={cn("absolute inset-y-0 right-0 flex items-center pr-3", {
              "cursor-pointer": !!onTrailingClick,
              "pointer-events-none": !onTrailingClick,
            })}
            onClick={!!onTrailingClick ? onTrailingClick : undefined}
          >
            <TrailingIcon className="h-5 w-5 text-jll-icon-base-subdued" />
          </div>
        )}
        {isClearable && !!props?.value?.toString()?.length && (
          <div
            className="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3"
            onClick={() => props?.onChange({ target: { value: "" } })}
          >
            <CloseOutlined className="h-5 w-5 text-jll-icon-base-subdued" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Input;
