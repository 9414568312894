import cn from "classnames";

interface LoadingPageProps {
  rows?: number;
  className?: string;
  mode?: "back" | "white";
}

const LoadingPage: React.FC<LoadingPageProps> = ({
  rows = 10,
  className,
  mode = "white",
}) => {
  return (
    <div
      className={cn(
        "animate-pulse flex flex-col w-full px-10 space-y-6 py-10",
        className
      )}
    >
      {[...Array(rows)].map((_, idx) => (
        <div className="flex flex-row space-x-4 w-full h-fit" key={idx}>
          <div
            className={cn("min-h-6 rounded w-full", {
              "bg-jll-text-base-subdued-3 bg-opacity-30": mode === "back",
              "bg-jll-surface-base-secondary": mode === "white",
            })}
          />
        </div>
      ))}
    </div>
  );
};

export default LoadingPage;
