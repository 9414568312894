import { toast } from "react-toastify";
import "./toast.css";
import { SolIcon } from "@solstice/sol-react";
import { SolIconNames } from "@solstice/sol-stencil";
import cn from "classnames";

const createToast = (message: string, type: string) => {
  let title = "";
  let icon = "check_circle";
  let classes = "";
  if (type === "success") {
    title = "Success";
    icon = "check";
    classes =
      "!bg-jll-color-surface-successSubdued before:bg-jll-color-text-successAccessible";
  } else if (type === "warn") {
    title = "Warning";
    icon = "warning";
    classes =
      "!bg-jll-color-surface-warning-subdued before:bg-jll-color-surface-warning-default";
  } else if (type === "error") {
    title = "Error";
    icon = "error";
    classes =
      "!bg-jll-color-surface-danger-subdued before:bg-jll-color-surface-danger-default";
  }
  const htmlMsg = (
    <div className="flex flex-col text-jll-text-base-default">
      <p className="mb-1">{title}</p>
      <p className="text-sm">{message}</p>
    </div>
  );
  const closeHtml = (
    <div className="flex justify-center items-center mx-4">
      <SolIcon icon="close" size="20" />
    </div>
  );

  toast(htmlMsg, {
    icon: (
      <div
        className={cn(
          "rounded-full min-w-10 min-h-10 flex justify-center items-center",
          {
            "bg-jll-surface-rag-success bg-opacity-20": type === "success",
            "bg-jll-surface-rag-warning bg-opacity-10": type === "warn",
            "bg-jll-surface-rag-danger bg-opacity-10": type === "error",
          }
        )}
      >
        <SolIcon
          icon={icon as SolIconNames}
          size="24"
          className={cn("", {
            "text-jll-icon-rag-success": type === "success",
            "text-jll-icon-rag-warning": type === "warn",
            "text-jll-icon-rag-danger": type === "error",
          })}
        />
      </div>
    ),
    closeOnClick: true,
    closeButton: closeHtml,
    className: `shadow-toast bg-white rounded !py-0 relative before:absolute before:content-[""] before:w-[6px] before:top-0  before:left-0 ${classes}`,
    position: "top-right",
  });
};

const Toast = (message: string) => {
  createToast(message, "error");
};

Toast.success = (message: string) => createToast(message, "success");
Toast.warn = (message: string) => createToast(message, "warn");
Toast.error = (message: string) => createToast(message, "error");

export default Toast;
