import { InfoOutlined } from "@material-ui/icons";
import {
  SolButton,
  SolDropdown,
  SolIcon,
  SolTextField,
} from "@solstice/sol-react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CountryModal } from "ui-molecules";
import { useContext, useEffect, useState } from "react";
import { Radio, SelectAutoComplete } from "ui-atoms";
import { BrokerProps, ProfileProps } from "types";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { GlobalContext } from "context";
import { useApiCall } from "hooks";
import { patchBrokerAPI } from "services";
import queryString from "query-string";
import { getOptions } from "utils";
import { URLS } from "constant";

interface IGeneral {
  broker: BrokerProps | null;
  profiles: ProfileProps[] | [];
  setBroker?: any;
  setProfiles?: any;
}

const INITIAL_VALUES = {
  first_name: "",
  last_name: "",
  job_title: "",
  email: "",
  telephone: "",
  linkedin: "",
  region: [],
  twitter: "",
  leadership: "",
};

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("This field is required"),
  last_name: Yup.string().required("This field is required"),
  email: Yup.string().required("This field is required").email("Invalid Email"),

  region: Yup.array().required("This field is required"),
});

const General: React.FC<IGeneral> = ({
  broker,
  profiles,
  setBroker,
  setProfiles,
}) => {
  const { state } = useContext(GlobalContext);
  const { meta } = state;
  const { brokerId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [patchBroker] = useApiCall(patchBrokerAPI);
  const [paramFilters, setParamFilters] = useState<any>(null);

  const leadershipOptions = Object.keys(meta?.broker?.leadership || [])?.map(
    (key: number | string) => ({
      value: Number(key),
      label: meta?.broker?.leadership?.[key]?.value,
    })
  );

  useEffect(() => {
    if (
      !broker ||
      (broker?.pk?.toString() !== brokerId &&
        broker?.id?.toString() !== brokerId)
    )
      return;
    let formValues: any = {};
    Object.keys(INITIAL_VALUES)?.forEach((key: string) => {
      if (key === "region") {
        formValues = {
          ...formValues,
          [key]: (broker as any)?.[key] ? [(broker as any)?.[key]] : [],
        };
        return;
      }
      formValues = {
        ...formValues,
        [key]: (broker as any)?.[key] || "",
      };
    });
    setValues({
      ...formValues,
    });
  }, [broker]);

  useEffect(() => {
    const filters = queryString.parse(location?.search, {
      arrayFormat: "bracket",
    });
    setParamFilters(filters);
  }, [location]);

  const {
    handleSubmit,
    handleBlur,
    setFieldValue,
    values,
    errors,
    touched,
    setValues,
  } = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema: validationSchema,
    onSubmit: async () => {},
  });

  const handleTab = (
    tab: string | number,
    sub: string | number | null,
    mode = "view"
  ) => {
    let updatedFilters = { ...paramFilters };
    updatedFilters["tab"] = tab?.toString();
    if (sub) updatedFilters["sub"] = sub?.toString();
    if (mode === "view") {
      delete updatedFilters["sub"];
      navigate(
        `${URLS.PROFILE.ROOT}/${brokerId}/?${queryString.stringify(
          updatedFilters,
          {
            arrayFormat: "bracket",
          }
        )}`
      );
    } else {
      navigate(
        `${URLS.PROFILE.ROOT}/${brokerId}/edit/?${queryString.stringify(
          updatedFilters,
          {
            arrayFormat: "bracket",
          }
        )}`
      );
    }
  };

  const handleUpdate = (key: string, value: any) => {
    try {
      patchBroker({
        pk: broker?.pk || broker?.id,
        [key]: value,
      }).then((res: any) => {
        if (!res) return;
        setBroker({
          ...broker,
          [key]: value,
        });
      });
    } catch (err) {}
  };

  return (
    <>
      <div className="w-full px-10 pt-8">
        <div className="mb-12">
          <h2 className="text-2xl mb-1">Profile</h2>
          <p className="text-sm text-jll-text-base-subdued">
            Add new country / language profile for this broker.
          </p>
        </div>
        {/* <div className="mb-12">
          {!!profiles?.length &&
            profiles?.map((profile: ProfileProps, idx: number) => (
              <div
                className="flex flex-row items-center space-x-9 mb-4"
                key={idx}
              >
                <Radio
                  id="aero-yes"
                  name="aero_opportunity"
                  value={profile?.country?.id}
                  label={
                    meta?.["country"]?.find(
                      (country: any) =>
                        country?.id?.toString() ===
                        profile?.country?.id?.toString()
                    )?.name
                  }
                  onChange={() => {}}
                  className="min-w-[180px]"
                />

                <div className="flex flex-row items-center space-x-3">
                  <SolButton
                    variant="secondary"
                    onSol-click={() => {
                      handleTab(profile?.country?.id, null);
                    }}
                  >
                    View
                    <SolIcon icon="visibility" />
                  </SolButton>
                  <SolButton
                    variant="secondary"
                    onSol-click={() => {
                      handleTab(profile?.country?.id, "license", "edit");
                      // handleSubTab("license");
                    }}
                  >
                    Edit
                    <SolIcon icon="edit" />
                  </SolButton>
                  <SolButton variant="secondary">
                    Delete
                    <SolIcon icon="delete" />
                  </SolButton>
                  <SolButton variant="outlined">
                    Unpublish
                    <SolIcon icon="public_off" />
                  </SolButton>
                </div>
              </div>
            ))}
        </div> */}
        <div className="flex flex-row items-center space-x-3 mb-12">
          <SolButton onSol-click={() => setIsOpen(true)} variant="primary">
            Add a country
            <SolIcon icon="add" />
          </SolButton>
          <InfoOutlined className="!w-5 !h-5 text-jll-icon-base-subdued" />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-row items-center grid md:grid-cols-3 grid-cols-2 gap-10 mb-12">
            <SelectAutoComplete
              name="leadership"
              label="Select Leadership type"
              className="!mb-0"
              options={leadershipOptions}
              value={leadershipOptions?.find(
                (item: any) => item?.value === values?.leadership
              )}
              onChange={(e) => {
                setFieldValue("leadership", e?.value);
              }}
              onBlur={(e: any) => {
                handleBlur(e);
                handleUpdate("leadership", values?.leadership);
              }}
              error={
                touched.leadership ? (errors.leadership as string) : undefined
              }
            />
          </div>
          <div className="flex flex-row items-center mb-10 grid md:grid-cols-3 grid-cols-2 gap-10">
            <SolTextField
              label="First name"
              required
              value={values.first_name}
              onSol-input={(e: any) => setFieldValue("first_name", e?.detail)}
              id="first_name"
              onBlur={(e) => {
                handleBlur(e);
                handleUpdate("first_name", values.first_name);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleUpdate("first_name", values.first_name);
                }
              }}
              size="small"
              errorLabel={touched?.first_name ? errors?.first_name : ""}
            />
            <SolTextField
              label="Last name"
              required
              value={values.last_name}
              onSol-input={(e: any) => setFieldValue("last_name", e?.detail)}
              id="last_name"
              onBlur={(e) => {
                handleBlur(e);
                handleUpdate("last_name", values.last_name);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleUpdate("last_name", values.last_name);
                }
              }}
              size="small"
              errorLabel={touched?.last_name ? errors?.last_name : ""}
            />

            <SolTextField
              label="Job title"
              value={values.job_title}
              onSol-input={(e: any) => setFieldValue("job_title", e?.detail)}
              id="job_title"
              onBlur={(e) => {
                handleBlur(e);
                handleUpdate("job_title", values.job_title);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleUpdate("job_title", values.job_title);
                }
              }}
              size="small"
              errorLabel={touched?.job_title ? errors?.job_title : ""}
            />
          </div>

          <div className="flex flex-row items-center grid md:grid-cols-3 grid-cols-2 gap-10 mb-10">
            <SolDropdown
              id="region"
              size="small"
              label="Region"
              options={Object.keys(meta?.["region"] || [])?.map(
                (key: number | string) => ({
                  value: Number(key),
                  label: meta?.["region"]?.[key],
                })
              )}
              required
              isMultiSelect={false}
              onUpdate-value={(e) => {
                handleUpdate("region", e?.detail);
                // setFieldValue("region", [e?.detail]);
              }}
              onBlur={(e) => {
                handleBlur(e);
              }}
              value={values?.region}
              errorLabel={
                touched.region ? (errors.region as string) : undefined
              }
            />
            <SolTextField
              label="Email"
              value={values.email}
              onSol-input={(e: any) => setFieldValue("email", e?.detail)}
              id="email"
              onBlur={(e) => {
                handleBlur(e);
                handleUpdate("email", values.email);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleUpdate("email", values.email);
                }
              }}
              inputFormat="email"
              size="small"
            />
            <SolTextField
              label="Telephone"
              value={values.telephone}
              onSol-input={(e: any) => setFieldValue("telephone", e?.detail)}
              id="telephone"
              onBlur={(e) => {
                handleBlur(e);
                handleUpdate("telephone", values.telephone);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleUpdate("telephone", values.telephone);
                }
              }}
              inputFormat="tel"
              size="small"
              errorLabel={touched?.telephone ? errors?.telephone : ""}
            />
          </div>

          <div className="flex flex-row items-center grid md:grid-cols-3 grid-cols-2 gap-10 mb-10">
            <SolTextField
              label="Linkedin URL"
              value={values.linkedin}
              onSol-input={(e: any) => setFieldValue("linkedin", e?.detail)}
              id="linkedin"
              onBlur={(e) => {
                handleBlur(e);
                handleUpdate("linkedin", values.linkedin);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleUpdate("linkedin", values.linkedin);
                }
              }}
              size="small"
              errorLabel={touched?.linkedin ? errors?.linkedin : ""}
            />
            <SolTextField
              label="Twitter URL"
              value={values.twitter}
              onSol-input={(e: any) => setFieldValue("twitter", e?.detail)}
              id="twitter"
              onBlur={(e) => {
                handleBlur(e);
                handleUpdate("twitter", values.twitter);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleUpdate("twitter", values.twitter);
                }
              }}
              size="small"
              errorLabel={touched?.twitter ? errors?.twitter : ""}
            />
            <div />
          </div>
        </form>
      </div>

      <CountryModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setProfiles={setProfiles}
        profiles={profiles}
      />
    </>
  );
};

export default General;
