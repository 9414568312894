import { ArrowBackOutlined } from "@material-ui/icons";
import { SolButton, SolIcon, SolTextField } from "@solstice/sol-react";
import { useApiCall } from "hooks";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { patchProfileAPI } from "services";
import { BrokerProps, ProfileProps } from "types";
import { LoadingPage } from "ui-molecules";

interface LicenseProps {
  profile: ProfileProps | null;
  setProfile?: any;
  isLoading?: boolean;
  broker?: BrokerProps | null;
}

const License: React.FC<LicenseProps> = ({
  profile,
  setProfile,
  isLoading,
  broker,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [patchProfile] = useApiCall(patchProfileAPI);
  const [license, setLicense] = useState("");

  useEffect(() => {
    setLicense(profile?.license);
  }, [profile]);

  const handleUpdate = (key: string, value: string) => {
    try {
      patchProfile({
        pk: profile?.id,
        [key]: value,
      }).then((res: any) => {
        if (!res) return;
        setProfile({
          ...profile,
          [key]: value,
        });
      });
    } catch (err) {}
  };

  return (
    <>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <div className="w-full px-10 pt-8">
          <div className="mb-12">
            <h2 className="text-xl mb-1">License data</h2>
            <p className="text-sm text-jll-text-base-subdued">
              Enter license information if no Worday license data is available.
            </p>
          </div>
          <div className="mb-[72px]">
            <div className="flex flex-row space-x-10 items-end">
              <SolTextField
                label="Real Estate License"
                size="small"
                required
                className="md:w-[380px] w-full"
                value={license}
                onSol-input={(e: any) => setLicense(e?.detail)}
                onBlur={(e) => {
                  handleUpdate("license", license);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleUpdate("license", license);
                  }
                }}
              />
            </div>
          </div>
          <div className="flex flex-row items-center space-x-10">
            <SolButton
              variant="outlined"
              onSol-click={() => {
                searchParams.set("sub", "awards");
                setSearchParams(searchParams);
              }}
            >
              <ArrowBackOutlined className="!w-5 !h-5" />
              Back
            </SolButton>
            {broker?.leadership && (
              <SolButton
                variant="outlined"
                onSol-click={() => {
                  searchParams.set("sub", "leader");
                  setSearchParams(searchParams);
                }}
              >
                Next
                <SolIcon icon="arrow_right_alt" />
              </SolButton>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default License;
