import { ArrowBackOutlined } from "@material-ui/icons";
import { PUBLISH_STATUS_PUBLISHED, URLS } from "constant";
import { useNavigate } from "react-router-dom";
import { LoadingPage } from "ui-molecules";
import { OfficeProps } from "types";
import { SolAvatar, SolBadge, SolButton, SolIcon, SolStatusIndicator } from "@solstice/sol-react";
import {
  SolStatusIndicatorSizeEnum,
  SolStatusIndicatorStatusEnum,
} from "@solstice/sol-stencil";
import cn from "classnames";
import { getFormatedDateTime } from "utils";

interface IDetailLeftbar {
  isLoading?: boolean;
  isLog?: boolean;
  locationData: OfficeProps;
}

const DetailLeftbar: React.FC<IDetailLeftbar> = ({
  isLoading,
  isLog,
  locationData,
}) => {
  const navigate = useNavigate();
  let local_status = 0; // 0 - No Text, 1: Match, 2: Not Match

  if (
    locationData?.publish_status === PUBLISH_STATUS_PUBLISHED &&
    !locationData?.dirty
  ) {
    local_status = 1;
  } else if (
    locationData?.publish_status === PUBLISH_STATUS_PUBLISHED &&
    locationData?.dirty
  ) {
    local_status = 2;
  }
  return (
    <div className="min-w-[280px] h-full bg-jll-surface-base-secondary relative">
      <div className="h-[68px] flex flex-row items-center pl-6 space-x-3 border-b border-b-jll-light-stroke-default">
        <button
          onClick={() => navigate(URLS.LOCATION.ROOT)}
          className="flex flex-row items-center"
        >
          <ArrowBackOutlined className="!w-5 !h-5 mr-2" />
          Back
        </button>
      </div>
      {isLoading ? (
        <LoadingPage mode="back" />
      ) : (
        <>
          <div className="absolute top-0 bottom-0 mt-[68px] py-8 space-y-8 overflow-y-auto w-full">
            <div className="border-b border-b-jll-light-stroke-default px-10 pb-8">
              <div className="flex flex-row items-center space-x-4 mb-4">
                <SolAvatar
                  name={""}
                  size="56"
                  image={
                    !!locationData?.images?.length
                      ? (locationData?.images?.[0]?.url as string)
                      : ""
                  }
                />
                <div>
                  <p>{locationData?.name || ""}</p>
                </div>
              </div>
              <div className="flex flex-row items-center space-x-2 mb-2">
                <span className="text-sm text-jll-text-base-subdued">
                  Status
                </span>
                <SolIcon
                  icon="error"
                  size="20"
                  className="text-jll-icon-base-subdued"
                />
              </div>
              <div className="flex flex-row space-x-2 items-center">
                <SolStatusIndicator
                  size={"16" as SolStatusIndicatorSizeEnum}
                  status={
                    (locationData?.publish_status === PUBLISH_STATUS_PUBLISHED
                      ? "available"
                      : "busy") as SolStatusIndicatorStatusEnum
                  }
                  online
                />
                <span className="text-sm">
                  {locationData?.publish_status === PUBLISH_STATUS_PUBLISHED
                    ? "Published"
                    : "Unpublished"}
                </span>
              </div>
              {local_status !== 0 && (
                <div className="flex flex-row space-x-1.5 items-center mt-2">
                  <SolIcon
                    icon="check_circle"
                    size="20"
                    className={cn(" -ml-0.5", {
                      "text-jll-icon-rag-success-1": local_status === 1,
                      "text-jll-surface-rag-warning-1": local_status === 2,
                    })}
                  />
                  <span className="text-sm">
                    {local_status === 1
                      ? "Local data matches"
                      : "Local data does not match"}
                  </span>
                </div>
              )}
            </div>

            <div className="px-10">
              <div className="mb-10">
                <SolBadge
                  variant="filled"
                  icon="save"
                  state="success"
                  className="mb-3"
                >
                  <span>Auto-saved</span>
                </SolBadge>
                <p className="text-jll-text-base-subdued text-sm">
                  {getFormatedDateTime(locationData?.update_timestamp)}
                </p>
              </div>

              <p className="text-sm mb-5">History</p>
              <div className="mb-10">
                <p className="text-jll-text-base-subdued text-sm mb-1">
                  Modified by
                </p>
                <p className="text-sm mb-1">ryong.ma@jll.com</p>
                <p className="text-jll-text-base-subdued text-sm mb-1">
                  Modified on
                </p>
                <p className="text-sm mb-1">
                  {getFormatedDateTime(locationData?.update_timestamp)}
                </p>
              </div>
              <div className="mb-10">
                {/* <p className="text-jll-text-base-subdued text-sm mb-1">
                  Created by
                </p>
                <p className="text-sm mb-1">ryong.ma@jll.com</p> */}
                <p className="text-jll-text-base-subdued text-sm mb-1">
                  Created on
                </p>
                <p className="text-sm mb-1">
                  {getFormatedDateTime(locationData?.create_timestamp)}
                </p>
              </div>

              {!isLog && (
                <SolButton
                  variant="secondary"
                  onSol-click={() =>
                    navigate(`${URLS.LOCATION.ROOT}/${locationData?.id}/log`)
                  }
                >
                  <SolIcon icon="history" />
                  View activity log
                  <SolIcon icon="arrow_right_alt" />
                </SolButton>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DetailLeftbar;
