import { useContext, useEffect, useState } from "react";
import { LayoutDetail } from "ui-organisms";
import {
  SolBadge,
  SolButton,
  SolIcon,
  SolTextField,
} from "@solstice/sol-react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Dropzone, Switch } from "ui-atoms";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { usePlacesWidget } from "react-google-autocomplete";
import MapWithPlaceID from "./GoogleMap";
import { useNavigate, useParams } from "react-router-dom";
import { useApiCall } from "hooks";
import {
  deleteOfficePublishAPI,
  getOfficeAPI,
  patchOfficeAPI,
  postImageAPI,
  postOfficeAPI,
  postOfficePublishAPI,
  putOfficeAPI,
} from "services";
import {
  PUBLISH_STATUS_PUBLISHED,
  PUBLISH_STATUS_REMOVED,
  SET_LOADING_MODAL,
  URLS,
} from "constant";
import { GlobalContext } from "context";
import { getFormatedDateTime, getUTCDate, parseJSON } from "utils";
import DetailLeftbar from "./DetailLeftbar";
import cn from "classnames";
import { LoadingPage } from "ui-molecules";

const INITIAL_VALUES = {
  name: "",
  phone: "",

  street: "",
  street2: "",
  city: "",
  state: "",
  country: "",
  zip: "",

  images: [],
  map_url: "",

  latitude: "",
  longitude: "",

  google_address: {},

  publish_status: PUBLISH_STATUS_REMOVED,
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("This field is required"),
  phone: Yup.string().required("This field is required"),
  street: Yup.string().required("This field is required"),
  city: Yup.string().required("This field is required"),
  state: Yup.string().required("This field is required"),
  country: Yup.string().required("This field is required"),
  zip: Yup.string().required("This field is required"),
});

const GOOGLE_MAP_KEY = parseJSON(process.env.REACT_APP_GOOGLE_MAP_KEY || "");

const LocationDetail = () => {
  const session = JSON.parse(sessionStorage.getItem("session") || "null");
  const { dispatch } = useContext(GlobalContext);
  const navigate = useNavigate();
  const { locationId } = useParams();
  const [getOffice] = useApiCall(getOfficeAPI);
  const [postOffice] = useApiCall(postOfficeAPI);
  const [patchOffice] = useApiCall(patchOfficeAPI);
  const [putOffice] = useApiCall(putOfficeAPI);
  const [postOfficePublish] = useApiCall(postOfficePublishAPI);
  const [deleteOfficePublish] = useApiCall(deleteOfficePublishAPI);
  const [placeId, setPlaceId] = useState(null);
  const [isAutoAddress, setIsAutoAddress] = useState(true);
  const [locationData, setLocationData] = useState<any>(null);

  const [isLoading, setIsLoading] = useState(false);

  const [formattedAddress, setFormattedAddress] = useState("");

  useEffect(() => {
    if (!locationId || locationId?.toLowerCase() === "new") {
      setValues(INITIAL_VALUES);
      setLocationData(null);
      return;
    }
    setIsLoading(true);
    getOffice(locationId)
      ?.then((res: any) => {
        if (!res) return;
        setLocationData(res);
        let formValues: any = {};
        Object.keys(INITIAL_VALUES)?.forEach((key: string) => {
          if (
            key === "google_address" &&
            res?.google_address &&
            !!Object?.keys(res?.google_address)?.length
          ) {
            formValues = {
              ...formValues,
              [key]: (res as any)?.[key] || "",
            };
            setFormattedAddress(res?.google_address?.formatted_address || "");
            setIsAutoAddress(true);
            return;
          }
          if (key === "zip") {
            formValues = {
              ...formValues,
              [key]: res?.zip,
            };
            return;
          }
          if (key === "images") {
            formValues = {
              ...formValues,
              [key]: res?.images || [],
            };
            return;
          }
          formValues = {
            ...formValues,
            [key]: (res as any)?.[key] || "",
          };
        });
        setValues({
          ...formValues,
        });
      })
      .finally(() => setIsLoading(false));
  }, [locationId]);

  const { ref } = usePlacesWidget({
    apiKey: GOOGLE_MAP_KEY,
    options: {
      types: ["address"],
    },
    onPlaceSelected: (place: any) => {
      let addressComponents: any = {};

      // Iterate over address components and store them in an object
      place.address_components.forEach((component: any) => {
        const types = component.types;

        if (types.includes("administrative_area_level_1")) {
          addressComponents.administrative_area_level_1 = component.long_name;
        }
        if (types.includes("locality")) {
          addressComponents.locality = component.long_name;
        }
        if (types.includes("postal_code")) {
          addressComponents.postal_code = component.long_name;
        }
        if (types.includes("country")) {
          addressComponents.google_country = component.long_name;
          addressComponents.google_country_short = component.short_name;
        }
        if (types.includes("political")) {
          addressComponents.political = component.long_name;
        }
      });

      addressComponents.latitude = place?.geometry?.location?.lat();
      addressComponents.longitude = place?.geometry?.location?.lng();

      addressComponents.place_id = place?.place_id;
      addressComponents.formatted_address = place?.formatted_address;

      setFieldValue("google_address", addressComponents);
      setFormattedAddress(place?.formatted_address);

      setPlaceId(place?.place_id);

      handleUpdate("google_address", addressComponents);
    },
  });

  const { handleBlur, setFieldValue, values, errors, touched, setValues } =
    useFormik({
      initialValues: INITIAL_VALUES,
      validationSchema: validationSchema,
      onSubmit: async () => {},
    });

  const handleUpdate = async (key: string, value: string) => {
    try {
      let attachPayload = {};
      if (
        !isAutoAddress &&
        (key === "street" ||
          key === "street2" ||
          key === "state" ||
          key === "city" ||
          key === "country" ||
          key === "latitude" ||
          key === "longitude")
      ) {
        attachPayload = {
          google_address: {},
        };
        setFieldValue("google_address", {});
      }
      if (locationId?.toLowerCase() !== "new")
        await patchOffice({
          pk: Number(locationId),
          [key]: value,
          ...attachPayload,
        });
      else
        postOffice({
          ...values,
          ...attachPayload,
          updated_user: session?.id,
        }).then((res: any) => {
          if (!res) return;
          navigate(`/location/${res?.id}`, { replace: true });
        });
    } catch (err) {}
  };

  const handleSubmitFile = async (name: string, files: any) => {
    if (files?.length <= 0) return;
    dispatch({
      type: SET_LOADING_MODAL,
      payload: {
        open: true,
        title: "Uploading files",
      },
    });
    try {
      let results: any[] = [];
      await Promise.all(
        files?.map(async (file: any) => {
          const result = await postImageAPI(file);
          results.push({
            name: file?.name,
            url: result,
            size: Number(file?.size),
            create_timestamp: getUTCDate(),
          });
        })
      );
      if (!!results?.length) {
        const payload: any = [...values?.images, ...results];
        await patchOffice({
          pk: Number(locationId),
          [name]: payload,
        });
        setFieldValue("images", payload);
      }
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            title: null,
          },
        });
      }, 100);
    } catch (err) {
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            title: null,
          },
        });
      }, 100);
    }
  };

  const deleteImg = async (idx: number) => {
    let newImages = [...values?.images];
    newImages.splice(idx, 1);
    await patchOffice({
      pk: Number(locationId),
      images: newImages,
    });
    setFieldValue("images", newImages);
  };

  const setPrimaryImg = async (idx: number) => {
    let newImages: any[] = [...values?.images];
    const [item]: any = newImages.splice(idx, 1);
    newImages.unshift(item);
    await patchOffice({
      pk: Number(locationId),
      images: newImages,
    });
    setFieldValue("images", newImages);
  };

  const handlePublish = () => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: true,
          title: "Publishing Profile...",
        },
      });
      postOfficePublish({
        office_location: locationId,
      })
        .then((res: any) => {
          if (!res) return;
          setLocationData({
            ...locationData,
            publish_status: res?.publish_status,
          });
        })
        ?.finally(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              title: "",
            },
          });
        });
    } catch (err) {}
  };

  const handleUnpublish = () => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: true,
          title: "Unpublishing Profile...",
        },
      });
      deleteOfficePublish(locationData?.id)
        .then((res: any) => {
          if (!res?.status) return;
          setLocationData({
            ...locationData,
            publish_status: PUBLISH_STATUS_REMOVED,
          });
        })
        ?.finally(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              title: "",
            },
          });
        });
    } catch (err) {}
  };

  return (
    <>
      <LayoutDetail>
        <DetailLeftbar locationData={locationData} isLoading={isLoading} />
        <div className="w-full h-full relative flex flex-col overflow-y-auto bg-white">
          <div className="min-h-[68px] flex flex-row items-center border-b border-b-jll-light-stroke-default justify-end px-10">
            <SolButton
              variant={
                locationData?.publish_status === PUBLISH_STATUS_PUBLISHED
                  ? "primary"
                  : "secondary"
              }
              onSol-click={
                locationData?.publish_status === PUBLISH_STATUS_PUBLISHED
                  ? handleUnpublish
                  : handlePublish
              }
              disabled={isLoading}
            >
              {locationData?.publish_status === PUBLISH_STATUS_PUBLISHED
                ? "Unpublish"
                : "Publish"}{" "}
              <SolIcon icon="public" />
            </SolButton>
          </div>
          {isLoading ? (
            <LoadingPage />
          ) : (
            <div className="w-full px-10 py-8">
              <section>
                <div className="mb-12">
                  <p className="text-xl mb-3">Office location details</p>
                  <p className="text-sm text-jll-text-base-subdued">
                    Please enter information about the JLL Office.
                  </p>
                </div>
                <div className="flex grid md:grid-cols-3 grid-cols-2 gap-10 mb-10">
                  <SolTextField
                    label="Office name"
                    required
                    value={values.name}
                    onSol-input={(e: any) => setFieldValue("name", e?.detail)}
                    id="name"
                    placeholder="Enter JLL office name"
                    onBlur={(e) => {
                      handleBlur(e);
                      handleUpdate("name", values.name);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleUpdate("name", values.name);
                      }
                    }}
                    size="small"
                    errorLabel={touched?.name ? errors?.name : ""}
                  />
                  <SolTextField
                    label="Phone number"
                    required
                    value={values.phone}
                    onSol-input={(e: any) => setFieldValue("phone", e?.detail)}
                    id="phone"
                    placeholder="Enter JLL office phone number"
                    onBlur={(e) => {
                      handleBlur(e);
                      handleUpdate("phone", values.phone);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleUpdate("phone", values.phone);
                      }
                    }}
                    size="small"
                    errorLabel={touched?.phone ? errors?.phone : ""}
                  />
                </div>

                <div className="flex w-fit mb-10">
                  <div className="flex flex-row justify-between space-x-10 items-center pt-6">
                    <div>
                      <p>Google autocomplete</p>
                      <p className="text-jll-text-base-subdued">
                        Alternatively use google autocomplete
                      </p>
                    </div>
                    <Switch
                      enabled={isAutoAddress}
                      onChange={(flag: any) => {
                        setIsAutoAddress(flag);
                      }}
                      className="!mb-0"
                    />
                  </div>
                </div>

                <div className="flex grid md:grid-cols-3 grid-cols-2 gap-10 mb-10">
                  {isAutoAddress && ref && (
                    <div className={cn("flex w-full col-span-2 relative")}>
                      <SolTextField
                        label="Office Address"
                        required
                        ref={ref as any}
                        value={formattedAddress}
                        onSol-input={(e: any) => setFormattedAddress(e?.detail)}
                        id="street"
                        placeholder="Enter JLL office address"
                        onBlur={(e) => {
                          handleBlur(e);
                          // !isAutoAddress && handleUpdate("street", values.street);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            // !isAutoAddress && handleUpdate("street", values.street);
                          }
                        }}
                        size="small"
                        errorLabel={touched?.street ? errors?.street : ""}
                        className="sol-w-full"
                      />
                    </div>
                  )}
                  {!isAutoAddress && (
                    <>
                      <SolTextField
                        label="Street 1"
                        required
                        value={values.street}
                        onSol-input={(e: any) =>
                          setFieldValue("street", e?.detail)
                        }
                        id="street"
                        placeholder="Enter Street"
                        onBlur={(e) => {
                          handleBlur(e);
                          handleUpdate("street", values.street);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleUpdate("street", values.street);
                          }
                        }}
                        size="small"
                        errorLabel={touched?.street ? errors?.street : ""}
                        className="sol-w-full"
                      />
                      <SolTextField
                        label="Street 2"
                        value={values.street2}
                        onSol-input={(e: any) =>
                          setFieldValue("street2", e?.detail)
                        }
                        id="street"
                        placeholder="Enter Street 2"
                        onBlur={(e) => {
                          handleBlur(e);
                          handleUpdate("street2", values.street2);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleUpdate("street2", values.street);
                          }
                        }}
                        size="small"
                        errorLabel={touched?.street2 ? errors?.street2 : ""}
                        className="sol-w-full"
                      />
                    </>
                  )}
                </div>

                {!isAutoAddress && (
                  <>
                    <div className="flex grid md:grid-cols-3 grid-cols-2 gap-10 mb-10">
                      <SolTextField
                        label="City"
                        required
                        value={values.city}
                        onSol-input={(e: any) =>
                          setFieldValue("city", e?.detail)
                        }
                        id="city"
                        placeholder="Enter City"
                        onBlur={(e) => {
                          handleBlur(e);
                          handleUpdate("city", values.city);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleUpdate("city", values.city);
                          }
                        }}
                        size="small"
                        errorLabel={touched?.city ? errors?.city : ""}
                      />
                      <SolTextField
                        label="State"
                        required
                        value={values.state}
                        onSol-input={(e: any) =>
                          setFieldValue("state", e?.detail)
                        }
                        id="state"
                        placeholder="Enter State"
                        onBlur={(e) => {
                          handleBlur(e);
                          handleUpdate("state", values.state);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleUpdate("state", values.state);
                          }
                        }}
                        size="small"
                        errorLabel={touched?.state ? errors?.state : ""}
                      />
                    </div>

                    <div className="flex grid md:grid-cols-3 grid-cols-2 gap-10 mb-10">
                      <SolTextField
                        label="Country"
                        required
                        value={values.country}
                        onSol-input={(e: any) =>
                          setFieldValue("country", e?.detail)
                        }
                        id="country"
                        placeholder="Enter Country"
                        onBlur={(e) => {
                          handleBlur(e);
                          handleUpdate("country", values.country);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleUpdate("country", values.country);
                          }
                        }}
                        size="small"
                        errorLabel={touched?.country ? errors?.country : ""}
                      />
                      <SolTextField
                        label="Postal Code"
                        required
                        value={values.zip}
                        onSol-input={(e: any) =>
                          setFieldValue("zip", e?.detail)
                        }
                        id="zip"
                        placeholder="Enter Postal Code"
                        onBlur={(e) => {
                          handleBlur(e);
                          handleUpdate("zip", values.zip);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleUpdate("zip", values.zip);
                          }
                        }}
                        size="small"
                        errorLabel={touched?.zip ? errors?.zip : ""}
                      />
                    </div>

                    <div className="flex grid md:grid-cols-3 grid-cols-2 gap-10 mb-10">
                      <SolTextField
                        label="Latitude"
                        inputFormat="number"
                        value={values?.latitude}
                        onSol-input={(e: any) =>
                          setFieldValue("latitude", e?.detail)
                        }
                        id="latitude"
                        placeholder="Enter Latitude"
                        onBlur={(e) => {
                          handleBlur(e);
                          handleUpdate("latitude", values.latitude);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleUpdate("latitude", values.latitude);
                          }
                        }}
                        size="small"
                        errorLabel={touched?.latitude ? errors?.latitude : ""}
                      />
                      <SolTextField
                        label="Longitude"
                        inputFormat="number"
                        value={values.longitude}
                        onSol-input={(e: any) =>
                          setFieldValue("longitude", e?.detail)
                        }
                        id="longitude"
                        placeholder="Enter Longitude"
                        onBlur={(e) => {
                          handleBlur(e);
                          handleUpdate("longitude", values.longitude);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleUpdate("longitude", values.longitude);
                          }
                        }}
                        size="small"
                        errorLabel={touched?.longitude ? errors?.longitude : ""}
                      />
                    </div>
                  </>
                )}
              </section>

              <section className="mb-12">
                <div className="mb-12">
                  <p className="text-xl mb-3">Location images</p>
                  <p className="text-sm text-jll-text-base-subdued">
                    Upload images related to this JLL office location.
                  </p>
                </div>
                <Dropzone
                  className="mb-12"
                  setDropFiles={(files: any) =>
                    handleSubmitFile("images", files)
                  }
                  acceptType="image/*"
                />

                <div className="grid md:grid-cols-4 grid-cols-3 gap-10">
                  {values?.images?.map((image: any, idx) => (
                    <div key={idx}>
                      <LazyLoadImage
                        src={image?.url}
                        className="object-cover w-full h-[180px] mb-6 rounded-md"
                      />
                      <SolBadge
                        size="small"
                        state={idx === 0 ? "success" : "idle"}
                        variant="filled"
                        icon={idx === 0 ? "check" : undefined}
                        className="[& .solBadge__content]:!rounded mb-3 cursor-pointer"
                        onClick={() => idx > 0 && setPrimaryImg(idx)}
                      >
                        <span>Use as primary</span>
                      </SolBadge>
                      <div className="mb-3">
                        <p className="truncate">{image?.name}</p>
                        <p className="text-jll-text-base-subdued">
                          {getFormatedDateTime(image?.create_timestamp)}
                        </p>
                      </div>
                      <SolButton
                        variant="outlined"
                        size="small"
                        onSol-click={() => deleteImg(idx)}
                      >
                        Delete <SolIcon icon="delete" />
                      </SolButton>
                    </div>
                  ))}
                </div>
              </section>

              <section>
                <div className="mb-12">
                  <p className="text-xl mb-3">Map</p>
                  <p className="text-sm text-jll-text-base-subdued">
                    View location details on the map.
                  </p>
                </div>

                {/* <div className="flex grid md:grid-cols-3 grid-cols-2 gap-10 mb-10">
                <SolTextField
                  label="Map URL"
                  required
                  value={values.map_url}
                  onSol-input={(e: any) => setFieldValue("map_url", e?.detail)}
                  id="map_url"
                  // placeholder="Enter Postal Code"
                  onBlur={(e) => {
                    handleBlur(e);
                    handleUpdate("map_url", values.map_url);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleUpdate("map_url", values.map_url);
                    }
                  }}
                  size="small"
                  errorLabel={touched?.zip ? errors?.zip : ""}
                />
              </div> */}

                <div>
                  {placeId ||
                    (((values?.latitude && values?.longitude) ||
                      ((values?.google_address as any)?.latitude &&
                        (values?.google_address as any)?.longitude)) &&
                      !!window?.google && (
                        <MapWithPlaceID
                          placeId={placeId}
                          lat={Number(
                            (values?.google_address as any)?.latitude ||
                              values?.latitude
                          )}
                          lng={Number(
                            (values?.google_address as any)?.longitude ||
                              values?.longitude
                          )}
                        />
                      ))}
                </div>
              </section>
            </div>
          )}
        </div>
      </LayoutDetail>
    </>
  );
};

export default LocationDetail;
