import queryString from "query-string";
import axios from "../axiosConfig";
import { API_PREFIX_URL, AUTH_API_PREFIX_URL } from "constant";
import { APIProps } from "types";

export const logoutApi = async () => {
  const res = await axios.get(`${AUTH_API_PREFIX_URL}/auth/logout/`);
  return res;
};

export const switchUserAPI = async (payload: any) => {
  const res = await axios.post(`${API_PREFIX_URL}/switch/`, payload);
  return res;
};

export const refreshApi = async (props: any) => {
  const param_str = queryString.stringify(props, {
    arrayFormat: "bracket",
  });
  const res = await axios.get(
    `${AUTH_API_PREFIX_URL}/auth/refresh/?${param_str}`
  );
  return res;
};
