import { OktaAuth } from "@okta/okta-auth-js";
import { parseJSON } from "utils";

const oktaAuth = new OktaAuth({
  issuer: `${parseJSON(
    process.env?.REACT_APP_OKTA_ISSUER || ""
  )}`,
  clientId: parseJSON(process.env?.REACT_APP_OKTA_CLIENT_ID || ""),
  redirectUri: parseJSON(process.env?.REACT_APP_OKTA_REDIRECT_URI || ""),
  scopes: ["profile", "openid", "email", "groups", "offline_access"],
  pkce: true,
});

export default oktaAuth;
