import { ArrowBackOutlined } from "@material-ui/icons";
import { SolBreadcrumbs, SolButton, SolIcon } from "@solstice/sol-react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ProfileProps } from "types";
import { useState } from "react";
import MainBio from "./MainBio";
import SectionBio from "./SectionBio";
import { useParams, useSearchParams } from "react-router-dom";
import { LoadingPage } from "ui-molecules";
import { useApiCall } from "hooks";
import { patchProfileAPI, postProfileGPTAPI } from "services";
import cn from "classnames";
import PromptBio from "./PromptBio";
import { Toast } from "ui-atoms";

interface BioProps {
  profile: ProfileProps | null;
  setProfile?: any;
  isLoading?: boolean;
}

const STEPS = [
  { label: "Bio Section", value: 1 },
  { label: "Generate", value: 2 },
  { label: "Review", value: 3 },
];

const Bio: React.FC<BioProps> = ({ profile, setProfile, isLoading }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(!!profile?.bio?.length ? 3 : 1);
  const [patchProfile] = useApiCall(patchProfileAPI);
  const { brokerId } = useParams();
  const [postProfileGPT] = useApiCall(postProfileGPTAPI);
  // const [prompt, setPrompt] = useState("");
  const [isGPTLoading, setIsGPTLoading] = useState(false);

  const handleGPT = async (prompt: string) => {
    try {
      if (!prompt?.length) {
        Toast.warn("Please fill out the Build Prompt first.");
        setActiveTab(2);
        return;
      }
      setActiveTab(3);
      setIsGPTLoading(true);
      const res = await postProfileGPT({
        broker_profile: searchParams?.get("tab"),
        prompt,
      });
      if (!res?.response?.length) {
        patchProfile({
          pk: profile?.id,
          bio: "",
        })
          .then((res1: any) => {
            if (!res1) return;
            setProfile({
              ...profile,
              bio: "",
            });
          })
          ?.finally(() => {
            setIsGPTLoading(false);
          });
        return;
      }
      patchProfile({
        pk: profile?.id,
        bio: res?.response,
      })
        .then((res1: any) => {
          if (!res1) return;
          setProfile({
            ...profile,
            bio: res1?.bio,
          });
        })
        ?.finally(() => {
          setIsGPTLoading(false);
        });
    } catch (err) {
      setIsGPTLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <div className="w-full overflow-hidden flex flex-col relative h-full">
          <div className="flex flex-row items-center space-x-6 py-6 mx-10">
            <div className="flex flex-row items-center">
              {STEPS?.map((step, idx) => (
                <div
                  className={cn(
                    "flex flex-row items-center text-jll-icon-base-subdued ",
                    {
                      "!text-jll-text-base-default": step?.value <= activeTab,
                    }
                  )}
                  key={idx}
                >
                  {idx !== 0 && (
                    <div className="mt-0.5 h-6 px-2">
                      <SolIcon icon="chevron_right" />
                    </div>
                  )}
                  <div
                    className="flex flex-row items-center space-x-2"
                    onClick={() => setActiveTab(step?.value)}
                  >
                    <div className="bg-jll-surface-base-secondary rounded-full w-7 h-7 flex justify-center items-center border border-jll-stroke-default-1">
                      {idx + 1}
                    </div>
                    <span className={cn("cursor-pointer")}>{step?.label}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="absolute left-0 right-0 bottom-0 top-[84px] w-full px-10 overflow-y-auto">
            {activeTab === 3 && (
              <MainBio
                profile={profile}
                setProfile={setProfile}
                isLoading={isGPTLoading}
                handleGPT={handleGPT}
                setActiveTab={setActiveTab}
                setIsLoading={setIsGPTLoading}
              />
            )}

            {activeTab === 2 && (
              <PromptBio
                handleGPT={handleGPT}
                setActiveTab={setActiveTab}
              />
            )}

            {activeTab === 1 && (
              <SectionBio profile={profile} setProfile={setProfile} setActiveTab={setActiveTab}/>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Bio;
