import cn from "classnames";

interface ILoading {
  size?: number;
  cols?: number;
  avatar?: boolean;
}
const Loading: React.FC<ILoading> = ({
  size = 6,
  cols = 10,
  avatar = true,
}) => {
  let columns: string[] = [];
  if (size === 4) {
    columns = ["w-2/12", "w-3/12", "w-3/12", "w-4/12"];
  } else if (size === 6) {
    columns = ["w-2/12", "w-1/12", "w-4/12", "w-1/12", "w-3/12", "w-2/12"];
  } else if (size === 2) {
    columns = ["w-4/12", "w-8/12"];
  }
  return (
    <div className="animate-pulse flex flex-col w-full divide-y divide-y-jll-stroke-default-1">
      {[...Array(cols)].map((_, idx) => (
        <div className="flex flex-row space-x-3 py-4" key={idx}>
          {columns?.map((column, idx1) => (
            <div
              className={cn(
                "flex flex-row items-center w-full space-x-2",
                column
              )}
              key={idx1}
            >
              {avatar && idx >= 1 && idx1 === 0 && (
                <div className="min-w-8 h-8 rounded-full bg-jll-surface-base-secondary" />
              )}
              <div
                key={idx1}
                className={cn("bg-jll-surface-base-secondary w-full h-6")}
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Loading;
