import { FC, useEffect, useState } from "react";
import cn from "classnames";

interface ICheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label?: string;
  error?: boolean;
  className?: string;
  labelClassName?: string;
  isMiss?: boolean;
  defaultChecked?: boolean;
  onChange?: any;
}

const Checkbox: FC<ICheckboxProps> = ({
  label = "",
  error,
  className,
  defaultChecked = false,
  onChange,
  labelClassName = "",
  isMiss,
  ...props
}) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(defaultChecked);
  }, [defaultChecked]);

  const handleChange = (e: any) => {
    setIsChecked(e.target.checked);
    onChange(e);
  };

  return (
    <div className={cn("flex space-x-3 items-center", className)}>
      <div className="flex h-4 items-center relative mt-1">
        <input
          type="checkbox"
          className={cn(
            "h-4 w-4 rounded-sm text-jll-light-stroke-default bg-white focus:ring-offset-0 focus:ring-0 cursor-pointer accent-jll-stroke-default appearance-none checked:appearance-auto forced-colors:appearance-auto",
            {
            //   "border-jll-color-surface-accent-default": !!error,
              "border border-jll-light-stroke-default": !error,
            }
          )}
          checked={isChecked}
          onChange={handleChange}
          {...props}
        />
      </div>
      {label && (
        <label
          htmlFor={props.id}
          className={cn(
            "cursor-pointer !font-normal flex items-center",
            labelClassName,
            {
              "text-jll-color-surface-accent-default": !!error,
            }
          )}
        >
          {label}
        </label>
      )}
    </div>
  );
};

export default Checkbox;
