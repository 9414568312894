import queryString from "query-string";
import axios from "../axiosConfig";
import { API_PREFIX_URL } from "constant";
import { APIProps } from "types";

export const getBrokerListAPI = async (props: APIProps) => {
  const param_str = queryString.stringify(props, {
    arrayFormat: "bracket",
  });
  const res = await axios.get(`${API_PREFIX_URL}/brokers/?${param_str}`);
  return res;
};

export const getBrokerAPI = async (pk: string | number) => {
  const res = await axios.get(`${API_PREFIX_URL}/brokers/${pk}/`);
  return res;
};

export const getUserListAPI = async (props: APIProps) => {
  const param_str = queryString.stringify(props, {
    arrayFormat: "bracket",
  });
  const res = await axios.get(`${API_PREFIX_URL}/asa/user/?${param_str}`);
  return res;
};

export const postBrokerAPI = async (data: any) => {
  const res = await axios.post(`${API_PREFIX_URL}/brokers/`, data);
  return res;
};

export const patchBrokerAPI = async (data: any) => {
  const pk = data?.pk;
  delete data?.pk;
  const res = await axios.patch(`${API_PREFIX_URL}/brokers/${pk}/`, data);
  return res;
};
