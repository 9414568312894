import queryString from "query-string";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Checkbox } from "ui-atoms";

interface FacetProps {
  label: string;
  id: string;
  count: number;
  parentId: string;
  value: string;
  pageId: string;
  defaultChecked?: boolean;
}

const Facet: React.FC<FacetProps> = ({ label, id, count, value, parentId }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [paramFilters, setParamFilters] = useState<any>(null);

  useEffect(() => {
    const filters = queryString.parse(location?.search, {
      arrayFormat: "bracket",
    });
    setParamFilters(filters);
  }, [location]);

  const onChangeCheckbox = (e: any) => {
    const isChecked = e.target.checked;
    const updatedFilters = { ...paramFilters };

    if (isChecked) {
      // Add facet
      if (!updatedFilters.hasOwnProperty(parentId)) {
        updatedFilters[parentId] = [value];
      } else if (
        !(
          updatedFilters[parentId].includes(value) ||
          updatedFilters[parentId].includes(value.toString())
        )
      ) {
        if (Array.isArray(updatedFilters[parentId])) {
          updatedFilters[parentId].push(value);
        } else {
          updatedFilters[parentId] = [updatedFilters[parentId], value];
        }
      }
    } else if (!isChecked) {
      // Remove facet
      if (
        updatedFilters.hasOwnProperty(parentId) &&
        (updatedFilters[parentId].includes(value) ||
          updatedFilters[parentId].includes(value.toString()))
      ) {
        updatedFilters[parentId] = updatedFilters[parentId].filter(
          (x: string) => x.toString() !== value.toString()
        );
      }
      if (
        updatedFilters.hasOwnProperty(parentId) &&
        !updatedFilters[parentId]?.length
      ) {
        delete updatedFilters[parentId];
      }
    }
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`,
    );
  };

  return (
    <div className="flex items-center space-x-3 justify-between">
      <Checkbox
        label={label}
        id={id}
        onChange={onChangeCheckbox}
        defaultChecked={
          (paramFilters?.[parentId] &&
            paramFilters?.[parentId]?.includes(value?.toString())) ||
          false
        }
      />
      <div className="text-sm bg-jll-surface-base-secondary-alpha-1 bg-opacity-10 rounded-full px-2.5 py-0.5">
        {count}
      </div>
    </div>
  );
};

export default Facet;
