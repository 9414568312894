import queryString from "query-string";
import axios from "../axiosConfig";
import { API_PREFIX_URL } from "constant";
import { APIProps } from "types";

export const getOfficesAPI = async (props: APIProps) => {
  const param_str = queryString.stringify(props, {
    arrayFormat: "bracket",
  });
  const res = await axios.get(`${API_PREFIX_URL}/office/?${param_str}`);
  return res;
};

export const getOfficeAPI = async (pk: string | number) => {
  const res = await axios.get(`${API_PREFIX_URL}/office/${pk}/`);
  return res;
};

export const postOfficeAPI = async (data: any) => {
  const res = await axios.post(`${API_PREFIX_URL}/office/`, data);
  return res;
}

export const putOfficeAPI = async (data: any) => {
  const pk = data?.pk;
  delete data?.pk;
  const res = await axios.put(`${API_PREFIX_URL}/office/${pk}/`, data);
  return res;
};

export const patchOfficeAPI = async (data: any) => {
  const pk = data?.pk;
  delete data?.pk;
  const res = await axios.patch(`${API_PREFIX_URL}/office/${pk}/`, data);
  return res;
};

export const deleteOfficeAPI = async (pk: number | string) => {
  const res = await axios.delete(`${API_PREFIX_URL}/office/${pk}/`);
  return res;
};

export const postOfficePublishAPI = async (data: any) => {
  const res = await axios.post(`${API_PREFIX_URL}/office/publish/`, data);
  return res;
}

export const deleteOfficePublishAPI = async (id: string | number) => {
  const res = await axios.delete(`${API_PREFIX_URL}/office/publish/${id}`);
  return res;
}
