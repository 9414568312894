import { parseJSON } from "utils";

export const AUTH_API_PREFIX_URL = parseJSON(
  process.env.REACT_APP_AUTH_API_PREFIX_URL || ""
);
export const API_CLOUDINARY_PREFIX_URL = parseJSON(
  process.env.REACT_APP_API_CLOUDINARY_PREFIX_URL || ""
);
export const API_PREFIX_URL = parseJSON(
  process.env.REACT_APP_API_PREFIX_URL || ""
);
