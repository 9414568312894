import { useEffect, useState } from "react";
import { FilteredFacetProps } from "types";
import Facet from "./Facet";
import cn from "classnames";
import { useLocation } from "react-router-dom";
import { getFilteredFacets } from "utils";

interface IFacets {
  className?: string;
  facets?: any;
  filterFacets?: any;
}

const FACET_MAX = 4;

const Facets: React.FC<IFacets> = ({ className, facets, filterFacets}) => {
  const location = useLocation();
  const [filteredFacets, setFilteredFacets] = useState<FilteredFacetProps[]>(
    []
  );

  useEffect(() => {
    if (facets) {
      setFilteredFacets(getFilteredFacets(facets));
    } else if (filterFacets) {
      setFilteredFacets(filterFacets);
    }
  }, [facets, filterFacets]);

  const onClickViewMore = (id: string) => {
    const updatedFacetsProps = filteredFacets?.map((facet) => {
      if (facet.id === id) {
        return {
          ...facet,
          isOpen: !facet.isOpen,
        };
      }
      return facet;
    });
    setFilteredFacets(updatedFacetsProps);
  };

  return (
    <div className="w-full h-full space-y-10">
      {filteredFacets?.map((facetCategory, facet_index) => {
        if (!facetCategory.facets?.length) {
          return null;
        }

        return (
          <div key={facet_index}>
            <p className="capitalize mb-4">{facetCategory.label}</p>
            <div className="space-y-4 transition-all">
              {facetCategory.facets.slice(0, FACET_MAX).map((facet, index) => {
                return (
                  <Facet
                    key={index}
                    pageId={location.pathname.replace("/", "")}
                    parentId={facetCategory.id}
                    {...facet}
                  />
                );
              })}
              <div
                className={cn(
                  "transition-all duration-500 ease max-h-[2000px] overflow-hidden space-y-4",
                  { "!max-h-0": !facetCategory.isOpen,
                    "!mb-4": facetCategory.isOpen,
                   }
                )}
              >
                {facetCategory.facets.slice(FACET_MAX).map((facet, index) => {
                  return (
                    <Facet
                      key={index}
                      pageId={location.pathname.replace("/", "")}
                      parentId={facetCategory.id}
                      {...facet}
                    />
                  );
                })}
              </div>
              {facetCategory.facets.length > FACET_MAX && (
                <button
                  className="flex items-center space-x-2 !mt-0"
                  onClick={() => onClickViewMore(facetCategory.id)}
                >
                  {/* {facetCategory.isOpen ? <FiChevronUp /> : <FiChevronDown />} */}
                  {facetCategory.isOpen && (
                    <span className="text-sm">View less</span>
                  )}
                  {!facetCategory.isOpen && (
                    <span className="text-sm">
                      View more ({facetCategory.facets.length - FACET_MAX})
                    </span>
                  )}
                </button>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Facets;
