// Convert decimal format (1000 => 1,000, 2023001 => 2,023,001)
export const getDecimalFormating = (
  num: number | undefined | string,
  isDecimal = false
) => {
  if (!num || isNaN(Number(num))) {
    return "";
  }
  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: 2,
    minimumFractionDigits: isDecimal ? 2 : 0,
  }).format(Number(num));
};

const base64UrlDecode = (str: string): string => {
  // Replace URL-safe characters with standard Base64 characters
  const base64 = str?.replace(/-/g, '+')?.replace(/_/g, '/');
  
  // Pad with '=' to make the length a multiple of 4
  const padding = base64?.length % 4 === 0 ? 0 : 4 - (base64?.length % 4);
  const paddedBase64 = base64 + '='.repeat(padding);
  
  // Decode Base64
  const decoded = atob(paddedBase64);
  
  return decoded;
}

export const decodeJwt = (token: string): any => {
  // Split the JWT into its parts
  const parts = token?.split('.');
  
  // Check if the JWT has three parts
  if (parts?.length !== 3) {
      throw new Error('Invalid JWT token');
  }
  
  // Decode the payload
  const payload = parts?.[1];
  const decodedPayload = base64UrlDecode(payload);
  
  // Parse the JSON string to an object
  return JSON.parse(decodedPayload);
}
