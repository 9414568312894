import {
  FacetsProps,
  BucketProps,
  FacetProps,
  FilteredFacetProps,
} from "types";
import { capitalize } from "./text";

export const getFilteredFacets = (facets: FacetsProps) => {
  return Object.keys(facets)
    .filter((key) => key !== "count")
    .map((key) => {
      // @ts-ignore fix ts(7053)
      const buckets: BucketProps[] = facets[key].buckets;

      return {
        id: key,
        label: capitalize(key.replaceAll("_", " ")),
        facets: [
          ...buckets.map((facet) => {
            let label = facet?.name || facet.val;
            if (label?.toString()?.toLowerCase() === "removed")
              label = "Inactive";
            if (key === "active_status" && label?.toString() === "1")
              label = "Active";
            if (key === "active_status" && label?.toString() === "2")
              label = "Inactive";
            return {
              count: facet.count,
              label: label,
              value: facet.val,
              id:
                typeof facet?.val === "string"
                  ? `${key}_${facet.val.toLowerCase().replaceAll(" ", "_")}`
                  : `${key}_${facet?.val}`,
            };
          }),
        ],
      };
    });
};

export const getOrderedFacets = (
  facets: FilteredFacetProps[],
  sortList: string[]
) => {
  const sortedFacets = facets.sort((a, b) => {
    return sortList.indexOf(a.id) - sortList.indexOf(b.id);
  });
  return sortedFacets;
};
