import queryString from "query-string";
import axios from "../axiosConfig";
import { API_PREFIX_URL } from "constant";
import { APIProps } from "types";

export const getIndustryAPI = async (props: APIProps) => {
  const param_str = queryString.stringify(props, {
    arrayFormat: "bracket",
  });
  const res = await axios.get(`${API_PREFIX_URL}/industry/?${param_str}`);
  return res;
};

export const postIndustryAPI = async (data: any) => {
  const res = await axios.post(`${API_PREFIX_URL}/industry/`, data);
  return res;
};

export const patchIndustryAPI = async (data: any) => {
  const pk = data?.pk;
  delete data?.pk;
  const res = await axios.patch(`${API_PREFIX_URL}/industry/${pk}/`, data);
  return res;
};

export const deleteIndustryAPI = async (pk: number) => {
  const res = await axios.delete(`${API_PREFIX_URL}/industry/${pk}/`);
  return res;
};

export const getServiceAPI = async (props: APIProps) => {
  const param_str = queryString.stringify(props, {
    arrayFormat: "bracket",
  });
  const res = await axios.get(`${API_PREFIX_URL}/service/?${param_str}`);
  return res;
};

export const postServiceAPI = async (data: any) => {
  const res = await axios.post(`${API_PREFIX_URL}/service/`, data);
  return res;
};

export const patchServiceAPI = async (data: any) => {
  const pk = data?.pk;
  delete data?.pk;
  const res = await axios.patch(`${API_PREFIX_URL}/service/${pk}/`, data);
  return res;
};

export const deleteServiceAPI = async (pk: number) => {
  const res = await axios.delete(`${API_PREFIX_URL}/service/${pk}/`);
  return res;
};
