import {
  SolBadge,
  SolButton,
  SolIcon,
  SolIconBubble,
} from "@solstice/sol-react";
import { SET_LOADING_MODAL } from "constant";
import { GlobalContext } from "context";
import { useApiCall } from "hooks";
import { useContext } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useParams } from "react-router-dom";
import { patchBrokerAPI, postImageAPI, postProfileAragonAPI } from "services";
import { BrokerProps } from "types";
import { Dropzone } from "ui-atoms";
import { Table } from "ui-molecules";
import { getFormatedDateTime, getUTCDate } from "utils";

interface ProfilePhotoProps {
  broker: BrokerProps | null;
  setBroker: any;
}

const ProfilePhoto: React.FC<ProfilePhotoProps> = ({ broker, setBroker }) => {
  const { dispatch } = useContext(GlobalContext);
  const { brokerId } = useParams();
  const [postProfileAragon] = useApiCall(postProfileAragonAPI);
  const [patchBroker] = useApiCall(patchBrokerAPI);

  const handleAragon = () => {
    try {
      postProfileAragon({ broker: brokerId })?.then((res: any) => {
        if (res?.url) window.open(res?.url, "_blank");
      });
    } catch (err) {}
  };

  const handleSubmitFile = async (name: string, files: any) => {
    if (files?.length <= 0) return;
    dispatch({
      type: SET_LOADING_MODAL,
      payload: {
        open: true,
        title: "Uploading files",
      },
    });
    try {
      let results: any[] = [];
      await Promise.all(
        files?.map(async (file: any) => {
          const result = await postImageAPI(file);
          // results.push({
          //   name: file?.name,
          //   url: result,
          //   size: Number(file?.size),
          //   create_timestamp: getUTCDate(),
          // });
          results.push(result);
        })
      );
      if (!!results?.length) {
        const payload: any = [...(broker?.photos || []), ...results];
        patchBroker({
          pk: broker?.pk || broker?.id,
          photos: payload,
        }).then((res: any) => {
          if (!res) return;
          setBroker({
            ...broker,
            photos: res?.photos,
          });
        });
      }
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            title: null,
          },
        });
      }, 100);
    } catch (err) {
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            title: null,
          },
        });
      }, 100);
    }
  };

  const deleteImg = async (idx: number) => {
    let newImages: any[] = [...(broker?.photos || [])];
    newImages.splice(idx, 1);
    patchBroker({
      pk: broker?.pk || broker?.id,
      photos: newImages,
    }).then((res: any) => {
      if (!res) return;
      setBroker({
        ...broker,
        photos: res?.photos,
      });
    });
  };

  const setPrimaryImg = async (idx: number) => {
    let newImages: any[] = [...(broker?.photos || [])];
    const [item]: any = newImages.splice(idx, 1);
    newImages.unshift(item);
    patchBroker({
      pk: broker?.pk || broker?.id,
      photos: newImages,
    }).then((res: any) => {
      if (!res) return;
      setBroker({
        ...broker,
        photos: res?.photos,
      });
    });
  };

  return (
    <>
      <div className="flex flex-col w-full h-full relative overflow-hidden">
        <div className="flex flex-col pt-8 mx-10 mb-12">
          <h3 className="text-xl mb-2">Profile image</h3>
          <p className="text-sm text-jll-text-base-subdued">
            Curabitur a ipsum eget dolor malesuada tristique
          </p>
        </div>
        <div className="flex flex-col mx-10 relative">
          <div className="grid grid-cols-2 gap-10 mb-12">
            <div className="col-span-1">
              <Dropzone
                setDropFiles={(files: any) => handleSubmitFile("photos", files)}
                acceptType="image/*"
                className="h-full"
              />
            </div>
            <div className="col-span-1">
              <div
                className="w-full flex h-full cursor-pointer bg-jll-surface-base-secondary-subdued border border-dashed border-jll-stroke-default-1 rounded-xl justify-center items-center p-6"
                onClick={handleAragon}
              >
                <div className="flex items-center flex-col">
                  <SolIconBubble
                    icon="cloud_upload"
                    size="40"
                    className="mb-3"
                  />
                  <p className="text-jll-text-base-subdued mb-3">
                    You will be redirected to Aragon, please follow the process
                  </p>
                  <SolButton
                    variant="outlined"
                    size="small"
                    className="sol-bg-transparent"
                  >
                    Click for AI generated photo
                  </SolButton>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="mb-12">
              <h3 className="text-xl mb-2">Profile photo - AI generated</h3>
              <p className="text-sm text-jll-text-base-subdued">
                Curabitur a ipsum eget dolor malesuada tristique
              </p>
            </div>
            <div className="grid md:grid-cols-4 grid-cols-3 gap-10">
              {broker?.photos?.map((image: any, idx) => (
                <div key={idx}>
                  <LazyLoadImage
                    src={image}
                    className="object-cover w-full h-[250px] mb-6 rounded-md"
                  />
                  <SolBadge
                    size="small"
                    state={idx === 0 ? "success" : "idle"}
                    variant="filled"
                    icon={idx === 0 ? "check" : undefined}
                    className="[& .solBadge__content]:!rounded mb-3 cursor-pointer"
                    onClick={() => idx > 0 && setPrimaryImg(idx)}
                  >
                    <span>Use as primary</span>
                  </SolBadge>
                  {/* <div className="mb-3">
                    <p className="truncate">{image?.name}</p>
                    <p className="text-jll-text-base-subdued">
                      {getFormatedDateTime(image?.create_timestamp)}
                    </p>
                  </div> */}
                  <SolButton
                    variant="outlined"
                    size="small"
                    onSol-click={() => deleteImg(idx)}
                  >
                    Delete <SolIcon icon="delete" />
                  </SolButton>
                </div>
              ))}
            </div>
          </div>

          <div>
            <div className="mb-12">
              <h3 className="text-xl mb-2">Profile photo -History</h3>
              <p className="text-sm text-jll-text-base-subdued">
                Curabitur a ipsum eget dolor malesuada tristique
              </p>
            </div>
            <div className="">
              <Table>
                <Table.Tbody>
                  <Table.Tr></Table.Tr>
                </Table.Tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfilePhoto;
