import {
  SolButton,
  SolDropdown,
  SolIcon,
  SolIconBubble,
  SolTextField,
} from "@solstice/sol-react";
import { SET_ALERT_MODAL } from "constant";
import { GlobalContext } from "context";
import { useApiCall } from "hooks";
import { useContext, useEffect, useState } from "react";
import {
  patchCityAPI,
  patchCountryAPI,
  patchIndustryAPI,
  patchMarketAPI,
  patchServiceAPI,
  patchStateAPI,
} from "services";
import { Switch, Toast } from "ui-atoms";
import { Modal } from "ui-molecules";
import { capitalize } from "utils";

interface EditTagModalProps {
  isOpen: boolean;
  setIsOpen: (flag: boolean) => void;
  kind: "industry" | "service" | "country" | "state" | "city" | "market";
  selectedItem?: any;
  loadData?: any;
}

const EditTagModal: React.FC<EditTagModalProps> = ({
  isOpen,
  setIsOpen,
  kind,
  selectedItem,
  loadData,
}) => {
  const { dispatch } = useContext(GlobalContext);
  const [patchIndustry] = useApiCall(patchIndustryAPI);
  const [patchService] = useApiCall(patchServiceAPI);
  const [patchCountry] = useApiCall(patchCountryAPI);
  const [patchState] = useApiCall(patchStateAPI);
  const [patchCity] = useApiCall(patchCityAPI);
  const [patchMarket] = useApiCall(patchMarketAPI);
  const [tagName, setTagName] = useState("");
  const [isBlur, setIsBlur] = useState(false);
  const [isRepublish, setIsRepublish] = useState(false);

  useEffect(() => {
    if (!isOpen || !selectedItem) return;
    setTagName(selectedItem?.name);
    setIsBlur(false);
  }, [isOpen, selectedItem]);

  const handleSubmit = () => {
    try {
      let API;
      switch (kind) {
        case "service":
          API = patchService;
          break;
        case "country":
          API = patchCountry;
          break;
        case "state":
          API = patchState;
          break;
        case "city":
          API = patchCity;
          break;
        case "market":
          API = patchMarket;
          break;
        default:
          API = patchIndustry;
      }
      API({ name: tagName, pk: selectedItem?.pk, republish: isRepublish }).then(
        (res: any) => {
          setIsOpen(false);
          loadData();
          if (!!res)
            Toast.success(
              `Successfully edited "${selectedItem?.name}" to "${tagName}"`
            );
          // dispatch({
          //   type: SET_ALERT_MODAL,
          //   payload: {
          //     open: true,
          //     kind: "success",
          //     title: `Successfully edited "${selectedItem?.name}" to "${tagName}"`,
          //     description:
          //       "Message content vel elit vel ex bibendum ullamcorper quis nec nunc. Morbi consectetur, quam non.",
          //     icon: "info",
          //     btn1: null,
          //     btn2: null,
          //   },
          // });
          // setTimeout(() => {
          //   dispatch({
          //     type: SET_ALERT_MODAL,
          //     payload: {
          //       open: false,
          //       kind: "",
          //       title: "",
          //       description: "",
          //       icon: "",
          //       btn1: null,
          //       btn2: null,
          //     },
          //   });
          // }, 1000);
        }
      );
    } catch (err) {}
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="default">
      <Modal.Header>
        <div className="flex flex-row justify-between items-start">
          <SolIconBubble icon="edit_square" size="56" variant="danger" />
          <SolButton variant="tertiary" onSol-click={() => setIsOpen(false)}>
            <SolIcon icon="close" />
          </SolButton>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="mt-4">
          <h3 className="text-xl mb-1">Edit {capitalize(kind)}</h3>
          <p className="mb-6">
            Edit tag. 
          </p>

          <div className="flex flex-col space-y-6">
            <SolTextField
              label={`${capitalize(kind)} name`}
              required
              // placeholder={`${kind} name`}
              size="small"
              onSol-input={(e) => {
                setTagName(e?.detail as string);
              }}
              onSol-blur={() => setIsBlur(true)}
              value={tagName}
              errorLabel={
                isBlur && !tagName?.length
                  ? "This field is required"
                  : undefined
              }
            />

            <div className="flex flex-row justify-between items-center w-full">
              <div>
                <div className="flex flex-row items-center space-x-3">
                  <span>Republish for all Users</span>
                  <div className="py-2 px-3 bg-jll-surface-rag-warning bg-opacity-10 rounded-full flex flex-row items-center space-x-2 w-fit">
                    <SolIcon
                      icon="group"
                      className="text-jll-surface-rag-warning-1"
                      size="16"
                    />
                    <span className="text-sm text-jll-icon-rag-warning">
                      {`${selectedItem?.count || 0} user${
                        selectedItem?.count > 1 ? "s" : ""
                      }`}
                    </span>
                  </div>
                </div>
                <p className="text-jll-text-base-subdued">
                  Currently {selectedItem?.count} user’s are associated with
                  this Industry
                </p>
              </div>

              <Switch
                enabled={isRepublish}
                onChange={(flag: any) => {
                  setIsRepublish(flag);
                }}
                className="mb-0"
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex flex-row w-full space-x-3 mt-6 justify-end">
          <SolButton
            variant="secondary"
            className="!w-[96px] sol-w-full"
            onSol-click={() => setIsOpen(false)}
          >
            Cancel
          </SolButton>
          <SolButton
            className="!w-[96px] sol-w-full"
            disabled={!tagName?.length}
            onSol-click={() => handleSubmit()}
          >
            Save
          </SolButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default EditTagModal;
