import {
  SET_ADD_PROFILE_MODAL,
  SET_ADD_TAG_MODAL,
  SET_ALERT_MODAL,
  SET_LOADING_MODAL,
  SET_META,
  SET_TOOLTIP,
} from "constant";
import { useReducer, createContext } from "react";

interface StateProps {}

const initialState: any = {
  meta: null,

  showTooltip: null,
  hideTooltip: null,

  isAddProfileModalOpen: false,
  isAddTagModalOpen: false,

  alertModal: {
    open: false,
    kind: "success",
    title: "",
    description: "",
    icon: "info",
    btn1: {
      label: "Save",
      onClick: null,
    },
    btn2: {
      label: "Cancel",
      onClick: null,
    },
  },

  loadingModal: {
    open: false,
    title: "",
  },
};

export const GlobalContext = createContext(initialState);

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case SET_META:
      return {
        ...state,
        meta: action?.payload,
      };

    case SET_ADD_PROFILE_MODAL:
      return {
        ...state,
        isAddProfileModalOpen: action?.payload,
      };

    case SET_ADD_TAG_MODAL:
      return {
        ...state,
        isAddTagModalOpen: action?.payload,
      };

    case SET_ALERT_MODAL:
      return {
        ...state,
        alertModal: action?.payload,
      };

    case SET_LOADING_MODAL:
      return {
        ...state,
        loadingModal: action?.payload,
      };

    case SET_TOOLTIP:
      return {
        ...state,
        showTooltip: action?.payload?.showTooltip,
        hideTooltip: action?.payload?.hideTooltip,
      };

    default:
      return {
        ...state,
      };
  }
};

export const GlobalContextProvider = (props: any) => {
  const [state, dispatch] = useReducer<any>(reducer, initialState);
  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {props.children}
    </GlobalContext.Provider>
  );
};
