import axios from "axiosConfig";
import { GlobalContext } from "context";
import Cookies from "js-cookie";
import { useState, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { refreshApi } from "services";
import { Toast } from "ui-atoms";
import { decodeJwt, parseJSON } from "utils";

const getAccessToken = async () => {
  try {
    const okta_tokens =
      parseJSON(sessionStorage.getItem("okta_tokens") || "") ||
      parseJSON(Cookies.get("okta_tokens") || "");

    let access_token = okta_tokens?.["access_token"];

    if (!access_token?.length) return;

    const decodedToken = decodeJwt(access_token);

    if (decodedToken?.exp * 1000 <= Date.now()) {
      const refresh_token = okta_tokens?.["refresh_token"];
      const scope = okta_tokens?.["scope"];
      const result = await refreshApi({
        refresh_token,
        scope,
      });
      if (!result?.data) return;
      await sessionStorage.setItem("okta_tokens", JSON.stringify(result?.data));
      access_token = result?.data?.["access_token"]
    }
    axios.defaults.headers.common["Authorization"] =
      decodeURIComponent(access_token);
  } catch (err) {
    sessionStorage.removeItem("okta_tokens");
    console.error(err);
  }
};

const useApiCall = (service: any, flag: boolean = false) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState<any>(null);

  const fetchData = useCallback(async (param: any) => {
    setLoading(true);
    try {
      await getAccessToken();
      const result = await service(param);
      setLoading(false);

      if (result?.status) {
        let backData = result?.data || result?.result;
        if ((result?.status === 204 || result?.status === 301) && !backData) {
          backData = { status: true };
        }
        setData(backData);
        return backData;
      }
    } catch (error: any) {
      setLoading(false);
      setError(error?.message || error?.detail);
      // if (error?.response?.status === HTTP_ERROR_UNAUTHORIZED) {
      //   navigate(`/`);
      //   return;
      // }

      // if (error?.response?.status === HTTP_ERROR_WRITE_ACCESS) {
      //   dispatch({
      //     type: SET_PERMISSION_MODAL,
      //     payload: {
      //       open: true,
      //     },
      //   });
      //   return;
      // }
      // if (error?.response?.status === HTTP_ERROR_BAD_REQUEST) {
      //   dispatch({
      //     type: SET_ERROR_MODAL,
      //     payload: {
      //       open: true,
      //       content: {
      //         code: error?.response?.status,
      //         values: error?.response?.data?.errors?.[0] || error?.response?.data,
      //       },
      //     },
      //   });
      //   return;
      // }
      if (
        !flag &&
        (error?.message || error?.detail?.message || error?.detail)
      ) {
        Toast.error(error?.message || error?.detail?.message || error?.detail);
      }
    }
  }, []);
  return [fetchData as Function, loading, data, error];
};

export default useApiCall;
