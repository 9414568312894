import { LayoutAuthHome } from "ui-organisms";
import Leftbar from "./Leftbar";
import { Input, SelectAutoComplete } from "ui-atoms";
import { SearchOutlined } from "@material-ui/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useMemo, useState } from "react";
import queryString from "query-string";
import debounce from "lodash.debounce";
import { getDecimalFormating } from "utils";
import { Loading, StyledUserValue, Table } from "ui-molecules";
import {
  GROUP_LOCAL_ADMIN,
  SEARCH_RESULT_LIMIT,
  STATUS_ACTIVE,
  USER_TABLE_COLUMNS,
} from "constant";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useApiCall } from "hooks";
import {
  deleteUserGroupAPI,
  deleteUserMarketAPI,
  getMarketAPI,
  getUserAPI,
  postUserGroupAPI,
  postUserMarketAPI,
} from "services";
import { GlobalContext } from "context";
import {
  DirectionEnum,
  FacetsProps,
  ManageUserProps,
  OptionProps,
} from "types";

interface MarketAccessItemProps {
  setData?: any;
  data?: any;
  item?: any;
}

const MarketAccessItem: React.FC<MarketAccessItemProps> = ({
  setData,
  data,
  item,
}) => {
  const [getMarket] = useApiCall(getMarketAPI);
  const [postUserMarket] = useApiCall(postUserMarketAPI);
  const [deleteUserMarket] = useApiCall(deleteUserMarketAPI);

  const loadMarketOptions = async (keyword: string) => {
    return getMarket({ keyword, active_status: [STATUS_ACTIVE] }).then(
      (res: any) => {
        return res?.docs?.map((market: any) => {
          return {
            value: market.pk,
            label: market?.name,
          };
        });
      }
    );
  };

  const onChangeRole = (user: ManageUserProps, markets: OptionProps[]) => {
    const userMarkets = user?.market || [];
    const updateMarkets = [...markets]?.map((x) => x.value) || [];
    const updateMarketLabels = [...markets]?.map((x) => x.label) || [];
    const deleteMarkets =
      [...userMarkets]?.filter((x) => {
        return !updateMarkets.includes(x);
      }) || [];
    updateMarkets?.forEach((market) => {
      postUserMarket({
        user: user.pk,
        market,
      });
    });
    deleteMarkets?.forEach((market) => {
      deleteUserMarket({
        user: user.pk,
        market,
      });
    });

    const index = data?.findIndex(
      (item: ManageUserProps) => item?.id === user?.id
    );
    if (index > -1) {
      setData((prev: any) => {
        const newItems = [...prev];
        newItems[index]["market"] = updateMarkets;
        newItems[index]["market_name"] = updateMarketLabels;
        return newItems;
      });
    }
  };

  return (
    <SelectAutoComplete
      placeholder="Market"
      className="!mb-0 min-w-[200px]"
      isMulti
      size="sm"
      loadOptions={loadMarketOptions}
      name="market"
      value={item?.market?.map((marketId: number, index: number) => ({
        value: marketId,
        label: item?.market_name?.[index] || "",
      }))}
      onChange={(option) => onChangeRole(item, option)}
    />
  );
};

const Userpage = () => {
  const session = JSON.parse(sessionStorage.getItem("session") || "null");
  const { dispatch, state } = useContext(GlobalContext);
  const { meta } = state;
  const location = useLocation();
  const navigate = useNavigate();
  const [getUser] = useApiCall(getUserAPI);
  const [postUserGroup] = useApiCall(postUserGroupAPI);
  const [deleteUserGroup] = useApiCall(deleteUserGroupAPI);
  const [data, setData] = useState<any[]>([]);
  const [paramFilters, setParamFilters] = useState<any>(null);
  const [page, setPage] = useState(0);
  const [keyword, setKeyword] = useState<string>("");
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstLoading, setIsFirstLoading] = useState(false);
  const [sortFields, setSortFields] = useState<string[]>([]);
  const [facets, setFacets] = useState<FacetsProps>();
  const isMarket = !!data?.find((item) =>
    item?.group?.includes(GROUP_LOCAL_ADMIN)
  );

  useEffect(() => {
    if (
      !session?.is_superuser &&
      !session?.is_staff &&
      !session?.permissions?.groups?.includes("Global Admin")
    )
      navigate("/");
  }, [session]);

  useEffect(() => {
    const filters = queryString.parse(location?.search, {
      arrayFormat: "bracket",
    });
    setParamFilters(filters);
  }, [location]);

  useEffect(() => {
    setPage(1);
    if (!paramFilters) return;
    loadData();
  }, [paramFilters]);

  const loadData = () => {
    setIsFirstLoading(true);
    getUser({
      page: 1,
      limit: SEARCH_RESULT_LIMIT,
      active_status: [STATUS_ACTIVE],
      ...paramFilters,
    })
      .then((res: any) => {
        if (!res) return;
        setData(res?.docs);
        setPage(res?.page);
        setTotal(res?.total);
        setFacets(res?.facets);
        setSortFields(res?.sort_fields);
      })
      .catch(() => {
        setData([]);
        setTotal(0);
      })
      .finally(() => {
        setIsFirstLoading(false);
      });
  };

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    setIsLoading(true);
    getUser({
      page: nextPage,
      limit: SEARCH_RESULT_LIMIT,
      active_status: [STATUS_ACTIVE],
      ...paramFilters,
    })
      .then((res: any) => {
        if (!res) return;
        setData((prevData: any) => [...prevData, ...res?.docs]);
        setPage(res?.page);
        setTotal(res?.total);
        setSortFields(res?.sort_fields);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: total > data?.length,
    onLoadMore: loadMore,
  });

  const updateKeywordFilter = (e: any) => {
    const updatedFilters = { ...paramFilters };
    if (e.target.value) {
      updatedFilters["keyword"] = e.target.value;
    } else if (updatedFilters.hasOwnProperty("keyword")) {
      delete updatedFilters.keyword;
    }
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const debounceUpdateKeyword = useMemo(
    () => debounce(updateKeywordFilter, 300),
    [paramFilters]
  );

  const onChangeInput = (e: any) => {
    setKeyword(e.target.value);
    debounceUpdateKeyword(e);
  };

  const onChangeRole = (user: ManageUserProps, groups: OptionProps[]) => {
    const userGroups = user?.group || [];
    const updateGroups = [...groups]?.map((x) => x.value) || [];
    const updateGroupLabels = [...groups]?.map((x) => x.label) || [];
    const deleteGroups =
      [...userGroups]?.filter((x) => {
        return !updateGroups.includes(x);
      }) || [];

    updateGroups?.forEach((group) => {
      postUserGroup({
        user: user.pk,
        group,
      });
    });
    deleteGroups?.forEach((group) => {
      deleteUserGroup({
        user: user.pk,
        group,
      });
    });

    const index = data?.findIndex(
      (item: ManageUserProps) => item?.id === user?.id
    );
    if (index > -1) {
      setData((prev) => {
        const newItems = [...prev];
        newItems[index]["group"] = updateGroups;
        newItems[index]["group_name"] = updateGroupLabels;
        return newItems;
      });
    }
  };

  const onClickSorting = (sort: string) => {
    const updatedFilters = {
      ...paramFilters,
      sort,
      direction:
        paramFilters?.direction === DirectionEnum.asc
          ? DirectionEnum?.desc
          : DirectionEnum.asc,
    };
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  return (
    <>
      <LayoutAuthHome>
        <Leftbar facets={facets} />
        <div className="w-full h-full relative">
          {/* Filter */}
          <div className="flex flex-row items-center py-6 mx-10 border-b border-b-jll-stroke-subdued justify-end">
            <div>
              <Input
                leadingIcon={SearchOutlined}
                isClearable
                onChange={onChangeInput}
                value={keyword}
                className="w-[350px]"
                placeholder="Search by name, country, city..."
              />
            </div>
          </div>

          <div className="flex flex-col mx-10 relative">
            <div className="flex flex-row items-center justify-between space-x-4 py-3 border-b border-b-jll-stroke-subdued">
              <span>{`${getDecimalFormating(total)} result${
                total > 1 ? "s" : ""
              }`}</span>
            </div>
          </div>

          <div className="absolute top-[143px] left-0 right-0 bottom-0 px-10 overflow-y-auto pt-4">
            {isFirstLoading ? (
              <Table.Loading avatar={false} size={4} />
            ) : (
              <>
                <Table>
                  <Table.Thead>
                    <Table.Tr>
                      {USER_TABLE_COLUMNS?.map((column, idx) => (
                        <Table.Th
                          key={idx}
                          filterId={column.id}
                          sortFields={sortFields}
                          filters={paramFilters}
                          onClick={onClickSorting}
                        >
                          {column?.label}
                        </Table.Th>
                      ))}
                      <Table.Th>Role</Table.Th>
                      {isMarket && <Table.Th>Market Access</Table.Th>}
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    {data?.map((item: any, idx: number) => (
                      <Table.Tr key={idx}>
                        {USER_TABLE_COLUMNS?.map((column, idx1) => (
                          <Table.Td key={idx1} className="!whitespace-normal">
                            <StyledUserValue user={item} valueKey={column.id} />
                          </Table.Td>
                        ))}
                        <Table.Td className="space-x-3">
                          <SelectAutoComplete
                            name="role"
                            className="!mb-0"
                            isMulti
                            size="sm"
                            options={[...meta?.user_group]?.map((x: any) => ({
                              value: x?.id,
                              label: x?.name,
                            }))}
                            placeholder="Select Role"
                            onChange={(option) => onChangeRole(item, option)}
                            value={item?.group?.map(
                              (groupId: number, index: number) => ({
                                value: groupId,
                                label: item?.group_name?.[index] || "",
                              })
                            )}
                          />
                        </Table.Td>
                        {isMarket && (
                          <Table.Td>
                            {item?.group?.includes(GROUP_LOCAL_ADMIN) ? (
                              <MarketAccessItem
                                setData={setData}
                                data={data}
                                item={item}
                              />
                            ) : (
                              <>-</>
                            )}
                          </Table.Td>
                        )}
                      </Table.Tr>
                    ))}
                  </Table.Tbody>
                </Table>
                {!!(total > data?.length) && (
                  <div
                    className="w-full py-2 flex justify-center items-center"
                    ref={sentryRef}
                  >
                    <Loading />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </LayoutAuthHome>
    </>
  );
};

export default Userpage;
