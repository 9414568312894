interface ILayoutAuthHome {
  children: any;
  className?: string;
}
const LayoutAuthHome = ({ children, className }: ILayoutAuthHome) => {
  return (
    <div
      className={`w-full flex justify-between items-center h-[80px] z-10 ${className}`}
    >
      {children}
    </div>
  );
};

export default LayoutAuthHome;
