import { SolButton, SolIcon } from "@solstice/sol-react";
import { Modal } from "ui-molecules";

interface PermissionModalProps {
  isOpen: boolean;
  setIsOpen: any;
}

const PermissionModal: React.FC<PermissionModalProps> = ({
  isOpen,
  setIsOpen,
}) => {
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Modal.Body>
        <div className="flex flex-row space-x-4">
          <div className="rounded-full min-w-[56px] max-h-[56px] flex justify-center items-center bg-jll-surface-rag-danger bg-opacity-5">
            <SolIcon
              icon="error"
              size="32"
              className="text-jll-icon-rag-danger"
            />
          </div>
          <div>
            <p>
              You are not authorized to access broker profile. Please contact{" "}
              <a href="mailto:brokertools@jll.com" target="_blank" className="text-jll-stroke-interaction">brokertools@jll.com</a> if
              you think you need access
            </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex flex-row space-x-3 justify-end">
          <SolButton
            variant="secondary"
            className="!w-[96px] sol-w-full"
            onSol-click={() => setIsOpen(false)}
          >
            Close
          </SolButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PermissionModal;
