import { SolCollapsableItem } from "@solstice/sol-react";
import { useEffect, useRef, useState } from "react";

interface CollapsableProps {
  children: any;
  isCollapse?: boolean;
  label?: string;
}

const customStyle = `.sol-collapse-item__header {
    border: none!important;
    padding: 14px 14px!important;
    height: 16px!important;
    border-radius: 3px!important;
    background: rgb(30, 105, 118, 0.1);
  }
    
  .sol-collapse-item__content {
    padding: 0px!important;
  }`;

const Collapsable: React.FC<CollapsableProps> = ({
  children,
  isCollapse,
  label,
}) => {
  const customComponentRef = useRef<HTMLElement | null>(null);
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    const webComponent = customComponentRef.current;

    if (webComponent) {
      const shadowRoot = webComponent.shadowRoot;

      if (shadowRoot) {
        // Create a new <style> element
        const styleElement = document.createElement("style");
        styleElement.textContent = customStyle;

        // Inject the style into the shadowRoot
        shadowRoot.appendChild(styleElement);
      }
    }
  }, []);

  useEffect(() => {
    setIsOpen(isCollapse || true);
  }, [isCollapse]);

  return (
    <SolCollapsableItem
      label={label}
      isCollapse={isOpen}
      onSol-click={() => setIsOpen((prev) => !prev)}
      style={{ width: "100%", display: "block" }}
      ref={customComponentRef as any}
    >
      <div className="px-4 py-3 border border-jll-stroke-default-1 border-t-0">
        {children}
      </div>
    </SolCollapsableItem>
  );
};

export default Collapsable;
