import { GlobalContext } from "context";
import { useContext } from "react";
import { Modal } from "ui-molecules";
import LoadGif from "assets/images/load.gif";

const LoadingModal = () => {
  const { state } = useContext(GlobalContext);
  const { loadingModal } = state;

  return (
    <Modal isOpen={loadingModal?.open} setIsOpen={() => {}} size="small">
      <div className="flex flex-col justify-center items-center">
        <img className="w-14 h-14 mb-4 object-none" src={LoadGif}/>
        <p>{loadingModal?.title}</p>
      </div>
    </Modal>
  );
};

export default LoadingModal;
