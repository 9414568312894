import { components, default as ReactSelect, StylesConfig } from "react-select";
import CreatableSelect from "react-select/creatable";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import cn from "classnames";
import { OptionProps } from "types";
import { Fragment, useEffect, useRef, useState } from "react";

// TODO better typing from react-select
interface SelectAutoCompleteProps {
  options?: OptionProps[];
  isMulti?: boolean;
  label?: string;
  labelClassName?: string;
  error?: any;
  optional?: boolean;
  placeholder?: string;
  className?: string;
  name: string;
  value?: OptionProps[] | OptionProps | string | number;
  onChange?: (e: any) => void;
  onBlur?: any;
  isDisabled?: boolean;
  loadOptions?: any;
  cacheOptions?: any;
  defaultOptions?: any;
  isClearable?: boolean;
  isCreatable?: boolean;
  isCustomCreatable?: boolean;
  isMenuPortalTarget?: boolean;
  size?: "default" | "sm" | "md";
  required?: boolean;
  isCustomMenu?: boolean;
}

const SelectAutoComplete: React.FC<SelectAutoCompleteProps> = ({
  label,
  error,
  optional,
  options,
  cacheOptions,
  defaultOptions,
  className,
  name,
  isCreatable,
  isCustomCreatable = false,
  onBlur,
  labelClassName,
  loadOptions,
  size = "default",
  required = false,
  isCustomMenu = false,
  isMenuPortalTarget = true,
  ...props
}) => {
  const createRef = useRef<any>(null);
  const selectRef = useRef<any>(null);

  const colourStyles: StylesConfig = {
    control: (base) => ({
      ...base,
      "&:hover": { borderColor: "#0C7BA1" }, // border style on hover
      border: "1px solid #CED6D8", // default border color
      boxShadow: "none", // no box-shadow
      minHeight: "auto",
      // height: size === "sm" ? "38px" : "auto",
    }),
    valueContainer: (base) => ({
      ...base,
      padding:
        size === "sm"
          ? "2px 4px 2px 10px"
          : size === "md"
          ? "4px 4px 4px 12px"
          : "6px 4px 6px 12px",
    }),
    input: (base) => ({
      ...base,
      padding: size === "md" ? "5px 0px" : "0px",
      margin: "0px",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    option: (base, { isSelected }) => ({
      ...base,
      padding: "6px 12px",
      "&:hover": { backgroundColor: isSelected ? "#295260" : "#F6F9FA" },
      backgroundColor: isSelected ? "#0C7BA1" : "white",
    }),
    multiValue: (base) => ({
      ...base,
      margin: size === "sm" ? "2px" : "2px",
      background: "rgba(30, 105, 118, 0.08)",
      padding: size === "md" ? "2px 8px" : "0px",
      borderRadius: "4px",
    }),
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
  };

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 32 && !!selectRef.current) {
      e.preventDefault();
      selectRef?.current?.onInputChange(
        selectRef?.current?.inputRef?.value + " ",
        "set-value"
      );
    }
  };

  return (
    <div className={cn("relative mb-6", className)}>
      {(label || optional) && (
        <div className="flex flex-row items-center">
          {label ? (
            <label
              className={cn(
                "mr-2 text-jll-text-base-subdued-2",
                labelClassName
              )}
            >
              {label}
              {required && (
                <span className="text-jll-stroke-danger ml-1">*</span>
              )}
            </label>
          ) : (
            <span></span>
          )}
        </div>
      )}
      <div className={cn({ "mt-[7px]": !!label })}>
        {isCustomCreatable && isCreatable && (
          <CreatableSelect
            options={options}
            className={cn({
              error: !!error,
            })}
            isClearable
            inputId={name}
            styles={colourStyles}
            onBlur={onBlur}
            backspaceRemovesValue={true}
            ref={createRef}
            menuPosition="fixed"
            menuPortalTarget={isMenuPortalTarget ? document.body : undefined}
            {...props}
          />
        )}
        {!isCustomCreatable && isCreatable && !loadOptions && (
          <CreatableSelect
            options={options}
            className={cn({
              error: !!error,
            })}
            isClearable
            inputId={name}
            styles={colourStyles}
            onBlur={onBlur}
            backspaceRemovesValue={true}
            menuPosition="fixed"
            menuPortalTarget={isMenuPortalTarget ? document.body : undefined}
            {...props}
          />
        )}
        {!isCustomCreatable && isCreatable && !!loadOptions && (
          <AsyncCreatableSelect
            cacheOptions
            defaultOptions
            loadOptions={loadOptions}
            className={cn({
              error: !!error,
            })}
            isClearable
            inputId={name}
            styles={colourStyles}
            onBlur={onBlur}
            backspaceRemovesValue={true}
            menuPosition="fixed"
            menuPortalTarget={isMenuPortalTarget ? document.body : undefined}
            {...props}
          />
        )}
        {!isCreatable && options && (
          <ReactSelect
            options={options}
            className={cn({
              error: !!error,
            })}
            isClearable
            inputId={name}
            styles={colourStyles}
            onBlur={onBlur}
            backspaceRemovesValue={true}
            menuPosition="fixed"
            menuPortalTarget={isMenuPortalTarget ? document.body : undefined}
            {...props}
          />
        )}
        {!isCreatable && !options && !isCustomMenu && (
          <AsyncSelect
            cacheOptions
            defaultOptions
            className={cn({
              error: !!error,
            })}
            isClearable
            styles={colourStyles}
            backspaceRemovesValue={true}
            loadOptions={loadOptions}
            onBlur={onBlur}
            ref={selectRef}
            onKeyDown={(e) => handleKeyDown(e)}
            menuPosition="fixed"
            menuPortalTarget={isMenuPortalTarget ? document.body : undefined}
            {...props}
          />
        )}

        {!isCreatable && !options && isCustomMenu && (
          <AsyncSelect
            cacheOptions
            defaultOptions
            className={cn({
              error: !!error,
            })}
            isClearable
            styles={colourStyles}
            backspaceRemovesValue={true}
            loadOptions={loadOptions}
            onBlur={onBlur}
            ref={selectRef}
            onKeyDown={(e) => handleKeyDown(e)}
            menuPortalTarget={isMenuPortalTarget ? document.body : undefined}
            {...props}
          />
        )}
      </div>
      {error && (
        <p
          className="absolute -bottom-5 text-jll-icon-rag-danger whitespace-nowrap truncate text-sm"
          title={error}
        >
          {error}
        </p>
      )}
    </div>
  );
};

export default SelectAutoComplete;
