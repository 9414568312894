import axios from "axiosConfig";
import { SET_META } from "constant";
import { GlobalContext } from "context";
import { useApiCall } from "hooks";
import Cookies from "js-cookie";
import { OriginalHome } from "pages";
import React, { useContext, useEffect, useRef } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getMetaAPI, getSessionAPI } from "services";
import { parseJSON } from "utils";

export const RequiredAuth: React.FC = () => {
  // const { oktaAuth, authState } = useOktaAuth();
  const [getSession, , , error] = useApiCall(getSessionAPI, true);
  const sessionInfoRef = useRef(null);
  const { dispatch, state } = useContext(GlobalContext);
  const { meta } = state;
  const [getMeta, , metaInfo] = useApiCall(getMetaAPI, true);
  const isLocal = parseJSON(
    process.env?.REACT_APP_AUTH_API_PREFIX_URL || ""
  )?.includes("local")
    ? true
    : false;

  useEffect(() => {
    if (!isLocal)
      axios.defaults.headers.common["subscription-key"] = parseJSON(
        process.env?.REACT_APP_APIM_SUBSCRIPTION_KEY || ""
      );
    getSession().then((res: any) => {
      if (!res) {
        sessionInfoRef.current = null;
        sessionStorage.removeItem("session");
        return;
      }
      sessionInfoRef.current = res;
      if (res?.mock_user) {
        sessionStorage.setItem("mainSession", JSON.stringify(res));
        sessionStorage.setItem("session", JSON.stringify(res?.mock_user));
      } else {
        sessionStorage.setItem("session", JSON.stringify(res));
        sessionStorage.removeItem("mainSession");
      }

      if (!!Cookies.get("okta_tokens")) {
        const tokens = parseJSON(Cookies.get("okta_tokens") || "");
        sessionStorage.setItem("okta_tokens", JSON.stringify(tokens));
        Cookies.remove("okta_tokens");
      }
    });
    if (!meta) {
      getMeta().then((response: any) => {
        dispatch({
          type: SET_META,
          payload: response,
        });
      });
    }
  }, []);

  if (!error && sessionInfoRef.current && (!!meta || !!metaInfo)) {
    return (
      <>
        {/* <Pendo sessionInfo={sessionInfo} /> */}
        <Outlet />
      </>
    );
  }
  return <>{error && <OriginalHome />}</>;
};

export const RequiredGroup = () => {
  const session = JSON.parse(
    sessionStorage.getItem("session") ||
      sessionStorage.getItem("mainSession") ||
      "null"
  );
  if (
    !!session?.permissions?.groups?.length ||
    !!session?.is_superuser ||
    !!session?.is_staff
  )
    return (
      <>
        <Outlet />
      </>
    );
  if (!session)
    return (
      <>
        <Navigate to={`/`} replace />
      </>
    );
  if (!session?.permissions?.broker_id)
    return (
      <Navigate to={`/permission`} replace />
    );
  return (
    <Navigate to={`/profile/${session?.permissions?.broker_id}`} replace />
  );
};
